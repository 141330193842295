import { createHoneybadger, createReporter } from '@betterplace/error-reporting'

// The given environment can look like
// 'development-felixwolfsteller', which then wouldnt be
// ignored by honeybadger by default. "test" and "development" do.
let honeybadgerEnv = window.HoneybadgerConfig?.environment

if (honeybadgerEnv?.startsWith('test-')) {
  honeybadgerEnv = 'test'
} else if (honeybadgerEnv?.startsWith('development-')) {
  honeybadgerEnv = 'development'
}
// If you want to report to honeybadger (for debugging, development),
// set the honeybadgerEnv to e.g. 'felix-is-testing'.

const honeybadger = createHoneybadger({
  apiKey: window.HoneybadgerConfig?.apiKey,
  environment: honeybadgerEnv,
  revision: window.HoneybadgerConfig?.revision,
  tags: window.HoneybadgerConfig?.tags,
  enableUnhandledRejection: false,
})

export const report = createReporter(honeybadger)
