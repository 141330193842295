import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { Modal, ShareButtons } from 'shared'
import { ModalContentProps, SharingGridButtonProps } from './types'

export const SharingGridButton = ({
  shareUrl,
  platforms,
  modalHeader,
  buttonLabel,
  size,
  block,
  variant = 'outline',
  utmMedium,
}: SharingGridButtonProps) => {
  const [show, setShow] = useState(false)

  const handleClick = useCallback(() => {
    setShow(true)
  }, [])

  const onHide = useCallback(() => {
    setShow(false)
  }, [])

  const classes = classNames(
    'btn',
    variant === 'outline' && 'btn-outline-green',
    variant === 'primary' && 'btn-primary',
    size === 'large' && 'btn-large',
    size === 'huge' && 'btn-huge',
    block && 'btn-block'
  )

  return (
    <>
      <button onClick={handleClick} className={classes}>
        {buttonLabel}
      </button>

      <Modal
        id="sharing-grid"
        show={show}
        onHide={onHide}
        content={<ModalContent shareUrl={shareUrl} platforms={platforms} utmMedium={utmMedium} />}
        header={modalHeader}
      />
    </>
  )
}

const ModalContent = ({ shareUrl, platforms, utmMedium }: ModalContentProps): JSX.Element => (
  <>
    <p>{i18n.t('projects.show.stats.share_modal_text')}</p>
    <ShareButtons
      project
      platforms={platforms}
      mobileOnly={['whatsapp', 'messenger']}
      shape="roundWithLabel"
      shareUrl={shareUrl}
      withLabel
      asGrid
      utmMedium={utmMedium}
    />
  </>
)
